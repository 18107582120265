import './thank-you.scss';
import React from 'react';
import {PageTitle} from '../title/title';
import AmazeeIoMetadata from '../metadata';
import AmazeePage from '../layout';

const ThankYouPage: React.FC<{ location: Location }> = ({ location, children }) => {
    return (
        <AmazeePage id="thank_you" location={location}>
            <div id='thank-you-page'>
                <PageTitle title="Thank you!" />
                <AmazeeIoMetadata title="Thank you!" location={location} />

                <div className="content">
                    {children}
                </div>
            </div>
        </AmazeePage>
    )
}

export default ThankYouPage;
